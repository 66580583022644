<template>
  <div>
    <v-row
      no-gutters
      align="baseline"
    >
      <document-name-ref
        :id="workItemTypeId"
        :cache-type="workItemTypeCacheType"
      />
      <span class="mr-2">- {{comment}}</span>
    </v-row>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    workItemTypeId () {
      return this.cache?.doc?.workItemTypeId
    },
    comment () {
      return this.cache?.doc?.comment
    }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.WorkItemTemplateRef,
      workItemTypeCacheType: ClientCache.CacheType.WorkItemTypeRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: String
  }
}
</script>

<style lang="stylus" scoped></style>
